import React from 'react'
import '../components/productos.css';

export const Productos = () => {
  return (
    <section className='contenedorProductos'>
        <div>
            <h1>Nuestros productos</h1>
        </div>
        <div className='container productos'>

        </div>
    </section>
  )
}
